<template>
  <div class="container px-4 mx-auto mt-10 max-w-3xl md:px-8">
    <h1>Account</h1>
    <div v-if="userForm">
      <base-form-input label="Name" v-model="userForm.name"/>
      <base-form-input label="Email" v-model="userForm.email"/>
      <base-form-input label="Role" :value="userForm.role" disabled/>
      <hr/>
      <h3 class="mt-4 mb-2 font-serif text-xl">Change Password</h3>
      <base-form-input label="New Password" v-model="newPassword" type="password"/>
      <base-form-input label="Password Confirmation" v-model="confirmPassword" type="password"/>
      <base-button class="px-0 mt-10 w-40" @click="save">
        <transition mode="out-in">
          <icon v-if="loading" name="loader" class="flex items-center h-7 animate-spin"/>
          <span v-else>Save Changes</span>
        </transition>
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { models } from 'feathers-vuex'

export default {
  name: 'Account',
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      userForm: null,
      loading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/user'
    })
  },
  methods: {
    save() {
      if (this.newPassword.length && this.confirmPassword.length) {
        if (this.newPassword === this.confirmPassword) {
          this.userForm.password = this.newPassword
        } else {
          this.error = 'Password confirmation doesn\'t match.'
          return
        }
      } else {
        this.$delete(this.userForm, 'password')
      }
      this.loading = true
      const { User } = models.api
      const user = new User(this.userForm)
      user.save().then(() => {
        setTimeout(() => {
          this.newPassword = ''
          this.confirmPassword = ''
          this.loading = false
        }, 100)
      })
    }
  },
  mounted() {
    if (!this.userForm) {
      this.userForm = this.auth.clone()
    }
  }
}
</script>
