<template>
  <div class="min-h-screen">
    <nav id="menu" class="overflow-y-auto fixed top-0 bottom-0 z-50 pb-6 w-60 text-lg leading-6 text-white bg-gray-900 transition-all duration-300 transform-gpu" :class="[ isMenuOpen ? '-translate-x-60 md:translate-x-0' : 'translate-x-0 md:-translate-x-60' ]" @click="closeOnMobile">
      <div class="hidden pt-6 mx-6 md:flex">
        <img class="flex-shrink-0 w-16" src="@/assets/images/logo-w-nt.svg" alt="Rebelstork">
      </div>
      <h3 class="mx-6 mt-6 mb-2 text-sm tracking-widest text-gray-400 uppercase">Region</h3>
      <div class="flex items-center mx-2" @click.stop>
        <select v-model="region" class="pl-4 m-0 w-full text-lg text-white bg-gray-800 rounded border-0 focus:ring-0">
          <option v-if="auth && ['super', 'admin'].includes(auth.role)" value="">All Regions</option>
          <option value="gta">Toronto</option>
          <option value="van">Vancouver</option>
          <option value="nyc">New York</option>
        </select>
      </div>
      <h3 class="mx-6 mt-8 mb-2 text-sm tracking-widest text-gray-400 uppercase">Main</h3>
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/">Dashboard</router-link>
      <!-- <router-link class="block pl-6 text-gray-500 hover:bg-gray-700" to="/orders">Orders</router-link> -->
      <!-- <router-link class="block pl-6 text-gray-500 hover:bg-gray-700" to="/inventory">Inventory</router-link> -->
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/sell">Sellers</router-link>
      <h3 class="mx-6 mt-8 mb-2 text-sm tracking-widest text-gray-400 uppercase">Intel</h3>
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/brands">Brands</router-link>
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/products">Products</router-link>
      <!-- <router-link class="block pl-6 text-gray-500 hover:bg-gray-700" to="/estimator">Estimator</router-link> -->
      <h3 class="mx-6 mt-8 mb-2 text-sm tracking-widest text-gray-400 uppercase">Logistics</h3>
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/postal">Service Area + Schedules</router-link>
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/pickup">Drop-off Locations</router-link>
      <!-- <router-link class="block pl-6 text-gray-500 hover:bg-gray-700" to="/regions">Regions</router-link> -->
      <h3 class="mx-6 mt-8 mb-2 text-sm tracking-widest text-gray-400 uppercase">User</h3>
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/users">Users</router-link>
      <router-link class="block py-2 pl-6 hover:bg-gray-700" to="/account">My Account</router-link>
      <!-- <router-link class="block pl-6 text-gray-500 hover:bg-gray-700" to="/settings">Settings</router-link> -->
      <a class="block py-2 pl-6 cursor-pointer hover:bg-gray-700" @click="logout">Logout</a>
    </nav>
    <!-- Desktop sidebar toggle -->
    <div class="hidden fixed top-6 z-50 transition-transform duration-300 transform-gpu md:block" :class="[ isMenuOpen ? 'md:translate-x-44' : 'md:translate-x-6' ]">
      <icon-button @click="toggleMenu" icon="menu" :dark="isMenuOpen"/>
    </div>
    <!-- Mobile top navbar -->
    <div class="flex fixed top-0 right-0 left-0 z-40 flex-shrink-0 justify-between items-center px-4 w-full h-12 bg-gray-900 md:hidden">
      <img class="flex-shrink-0 w-12" src="@/assets/images/logo-w-nt.svg" alt="">
      <icon-button @click="toggleMenu" icon="menu" class="text-white" dark/>
    </div>
    <!-- Mobile menu mask -->
    <div class="fixed inset-0 z-30 bg-gray-900 transition-all duration-300 md:hidden" :class="[ !isMenuOpen ? 'bg-opacity-50 pointer-events-auto' : 'bg-opacity-0 pointer-events-none' ]" @click="toggleMenu"></div>
    <main class="pt-12 transition-all duration-300 md:pt-0" :class="[ isMenuOpen ? 'md:pl-60' : 'md:pl-0' ]">
      <keep-alive>
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </main>
    <notifications/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Notifications from '@/components/Notifications.vue'

export default {
  name: 'Home',
  components: {
    Notifications
  },
  computed: {
    ...mapState(['isMenuOpen']),
    ...mapGetters({ auth: 'auth/user' }),
    region: {
      get() {
        return this.$store.getters.region
      },
      set(val) {
        this.auth.patch({ data: { settings: { ...this.auth.settings, region: val } } })
      }
    }
  },
  methods: {
    closeOnMobile() {
      if (document.body.clientWidth < 768) {
        this.$store.commit('toggleMenu', true)
      }
    },
    toggleMenu() {
      this.$store.commit('toggleMenu')
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.go('/login')
      })
    }
  }
}
</script>
