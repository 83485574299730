<template>
  <div class="flex items-center w-full min-h-screen login-window">
    <div class="flex-1 p-8 mx-auto max-w-md bg-white md:ml-20">
      <img class="mb-6 w-1/3" src="../assets/images/logo.svg" alt=""/>
      <div v-if="form.error" class="flex items-center py-2 px-4 text-red-700 bg-red-50 rounded">
        <icon name="alert-triangle" size="16"/><span class="ml-3">{{ form.error }}</span>
      </div>

      <form ref="form" @submit.prevent="onSubmit">
        <div class="field">
          <base-floating-input
            id="login-user"
            v-model="form.username"
            label="Username"
          />
        </div>

        <div class="field">
          <base-floating-input
            v-model="form.password"
            label="Password"
            type="password"
          />
        </div>
        <base-button type="submit" outline icon-right="arrow-right" class="mt-6">
          Login
        </base-button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: '',
        error: ''
      }
    }
  },
  mounted() {
    this.$store
      .dispatch('auth/authenticate')
      .then(() => {
        this.$router.push('/')
      })
      .catch(() => {})
  },
  methods: {
    onSubmit() {
      const email = this.form.username
      const password = this.form.password
      this.form.error = ''
      this.$store
        .dispatch('auth/authenticate', {
          strategy: 'local',
          email,
          password
        })
        .then(() => {
          this.$router.push('/')
        })
        // Just use the returned error instead of mapping it from the store.
        .catch((err) => {
          // Convert the error to a plain object and add a message.
          const type = err.className
          err = Object.assign({}, err)
          err.message =
            type === 'not-authenticated'
              ? 'Incorrect username or password.'
              : 'An error prevented login.'
          this.form.error = err.message
        })
    }
  }
}
</script>

<style>
.login-window {
  background-image: url("../assets/images/toronto.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
