<template>
  <div class="container px-4 pt-10 mx-auto md:px-8">
    <div class="flex flex-wrap items-center">
      <h1 class="flex-1">Sellers</h1>
      <search v-model="search" label="Search" class="w-full lg:pl-4 lg:w-96"/>
    </div>
    <div class="flex flex-wrap items-center pb-4 w-full lg:w-auto">
      <div class="flex flex-wrap mt-2 w-full lg:w-auto lg:mr-auto">
        <select
          class="p-0 px-2 mt-1 w-full h-8 text-sm bg-white rounded border border-gray-200 shadow-sm md:w-48 md:flex-initial md:mr-2 focus:outline-none focus:border-gray-500 focus:ring-0"
          name="Filter"
          v-model="sortOptions"
          @change="pagination.$skip = 0"
        >
          <option value="createdAt:-1">Recently submitted</option>
          <option value="updatedAt:-1">Recently updated</option>
          <option value="createdAt:1">Least recently submitted</option>
          <option value="updatedAt:1">Least recently updated</option>
        </select>
        <select
          class="p-0 px-2 mt-1 w-full h-8 text-sm bg-white rounded border border-gray-200 shadow-sm md:mr-2 md:w-40 md:flex-initial focus:outline-none focus:border-gray-500 focus:ring-0"
          name="Filter"
          v-model="filterStatus"
          @change="pagination.$skip = 0"
        >
          <option :value="null">All Status</option>
          <option value="nc">Needs Confirmation</option>
          <option value="ap">Awaiting Pickup</option>
          <option value="ad">Awaiting Dropoff</option>
          <option value="do">Dropped Off</option>
          <option value="it">In Transit</option>
          <option value="ar">Archived</option>
        </select>
        <v-date-picker
          class="relative w-full md:w-auto md:mr-2"
          v-model="filterDate"
          :model-config="modelConfig"
          :attributes="attributes"
          :masks="{ input: 'DD MMM YYYY' }"
        >
          <template v-slot="{ inputValue, togglePopover, hidePopover }">
            <div class="py-0 px-3 mt-1 w-full h-8 text-sm leading-8 rounded border border-gray-200 shadow-sm select-none md:flex-initial md:w-36 focus:outline-none focus:ring-0" @click.stop="togglePopover">
              {{ inputValue || 'Pickup Date' }}
            </div>
            <div v-if="!inputValue" class="flex absolute top-0 right-0 items-center py-3 pr-2 pointer-events-none">
              <icon name="chevron-down" size="16" class="flex-shrink-0 text-gray-500 hover:text-gray-600"/>
            </div>
            <div v-else class="flex absolute top-0 right-0 items-center py-3 pr-2" @click.stop="clearDate(hidePopover)">
              <icon name="x" size="16" class="flex-shrink-0 text-gray-500 hover:text-gray-600"/>
            </div>
          </template>
        </v-date-picker>
        <v-date-picker
          class="relative w-full md:w-auto"
          v-model="filterCreatedDate"
          :attributes="attributes"
          :masks="{ input: 'DD MMM YYYY' }"
        >
          <template v-slot="{ inputValue, togglePopover, hidePopover }">
            <div class="py-0 px-3 mt-1 w-full h-8 text-sm leading-8 rounded border border-gray-200 shadow-sm select-none md:flex-initial md:w-36 focus:outline-none focus:ring-0" @click.stop="togglePopover">
              {{ inputValue || 'Submitted Date' }}
            </div>
            <div v-if="!inputValue" class="flex absolute top-0 right-0 items-center py-3 pr-2 pointer-events-none">
              <icon name="chevron-down" size="16" class="flex-shrink-0 text-gray-500 hover:text-gray-600"/>
            </div>
            <div v-else class="flex absolute top-0 right-0 items-center py-3 pr-2" @click.stop="clearCreatedDate(hidePopover)">
              <icon name="x" size="16" class="flex-shrink-0 text-gray-500 hover:text-gray-600"/>
            </div>
          </template>
        </v-date-picker>
      </div>
      <div>
        <pagination v-model="pagination" :latest-query="latestQuery"/>
      </div>
    </div>
    <div class="mb-12">
      <sell-item v-for="item in items" :key="item.id" :item="item"/>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { startOfDay, endOfDay } from 'date-fns'

import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SellItem from '@/components/SellItem'

export default {
  name: 'Sell',
  components: {
    Pagination,
    Search,
    'v-date-picker': DatePicker,
    SellItem
  },
  setup(_, { root }) {
    const { Sell } = models.api

    const search = ref('')
    const filterStatus = ref(null)
    const filterDate = ref(null)
    const filterCreatedDate = ref(null)
    const sortOptions = ref('createdAt:-1')
    const pagination = ref({
      $limit: 10,
      $skip: 0
    })
    const region = computed(() => root.$store.getters.region)

    const modelConfig = {
      type: 'string',
      mask: 'YYYY-MM-DD'
    }
    const attributes = [
      {
        dot: 'red',
        dates: new Date()
      }
    ]

    const sort = computed(() => {
      const sort = {}
      if (sortOptions.value) {
        const key = sortOptions.value.split(':')[0]
        const value = sortOptions.value.split(':')[1]
        sort[key] = value
      }
      return sort
    })

    const fetchParams = computed(() => {
      const query = {
        $or: [
          { 'data.name': { $iLike: '%' + search.value.trim() + '%' } },
          { 'data.email': { $iLike: '%' + search.value.trim() + '%' } },
          { 'data.phone': { $iLike: '%' + search.value.trim() + '%' } },
          { 'data.address': { $iLike: '%' + search.value.trim() + '%' } }
        ],
        $sort: sort.value
      }
      Object.assign(query, pagination.value)
      if (filterStatus.value) { Object.assign(query, { status: filterStatus.value }) }
      if (filterDate.value) { Object.assign(query, { 'data.logistics.date': filterDate.value }) }
      if (filterCreatedDate.value) { Object.assign(query, { createdAt: { $gt: startOfDay(filterCreatedDate.value).toISOString(), $lt: endOfDay(filterCreatedDate.value).toISOString() } }) }
      if (region.value !== '') { Object.assign(query, { 'data.region': region.value }) }
      return { query, qid: 'sellerLocal' }
    })

    const params = computed(() => {
      const query = {
        $or: [
          { 'data.name': { $regex: search.value.toUpperCase().trim(), $options: 'i' } },
          { 'data.email': { $regex: search.value.toUpperCase().trim(), $options: 'i' } },
          { 'data.phone': { $regex: search.value.toUpperCase().trim(), $options: 'i' } },
          { 'data.address': { $regex: search.value.toUpperCase().trim(), $options: 'i' } }
        ],
        $sort: sort.value
      }
      Object.assign(query, pagination.value)
      if (filterStatus.value) { Object.assign(query, { status: filterStatus.value }) }
      if (filterDate.value) { Object.assign(query, { 'data.logistics.date': filterDate.value }) }
      if (filterCreatedDate.value) { Object.assign(query, { createdAt: { $gt: startOfDay(filterCreatedDate.value).toISOString(), $lt: endOfDay(filterCreatedDate.value).toISOString() } }) }
      if (region.value !== '') { Object.assign(query, { 'data.region': region.value }) }
      return { query, qid: 'sellerFetch' }
    })

    const { items, latestQuery, isPending } = useFind({ model: Sell, params, fetchParams })

    const clearDate = (hidePopover) => {
      filterDate.value = null
      hidePopover()
    }

    const clearCreatedDate = (hidePopover) => {
      filterCreatedDate.value = null
      hidePopover()
    }

    return {
      search,
      filterStatus,
      filterDate,
      filterCreatedDate,
      clearDate,
      clearCreatedDate,
      modelConfig,
      params,
      fetchParams,
      attributes,
      sortOptions,
      pagination,
      items,
      latestQuery,
      isPending
    }
  }
}
</script>
