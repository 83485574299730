<template>
  <div class="container px-4 pt-10 mx-auto md:px-8">
    <div class="flex items-start">
      <h1 class="flex-1">Users Database</h1>
      <base-button
        icon-right="plus"
        class="py-1 bg-green-600 md:py-2"
        @click="toggleForm"
      >
        Create
      </base-button>
    </div>
    <p class="mt-3 text-lg"></p>
    <div class="flex flex-wrap items-center py-2">
      <search v-model="search" label="Find a user" class="w-full lg:w-5/12 lg:mr-auto"/>
      <div class="flex items-center w-full lg:w-auto lg:justify-end">
        <pagination v-model="pagination" :latest-query="latestQuery"/>
      </div>
    </div>
    <div class="mb-12">
      <div v-for="u in users" :key="u.id" class="flex flex-wrap p-4 mb-2 bg-white rounded-md border border-gray-200 shadow hover:border-gray-400">
        <div class="mb-2 w-3/4 lg:mb-0 lg:w-4/12">
          <p>{{ u.name }}</p>
          <p class="text-sm text-gray-500">Name</p>
        </div>
        <div class="flex justify-end items-start ml-auto lg:hidden lg:w-1/4">
          <icon-button v-if="canEdit(u)" @click="editUser(u)" class="flex-shrink-0 mx-px" icon="edit" size="18"/>
        </div>
        <div class="mb-2 w-full lg:mb-0 lg:w-4/12">
          <p>{{ u.email }}</p>
          <p class="text-sm text-gray-500">Email</p>
        </div>
        <div class="w-full lg:w-2/12">
          <p class="capitalize">{{ u.role }}</p>
          <p class="text-sm text-gray-500">Role</p>
        </div>
        <div class="hidden justify-end items-center ml-auto lg:flex lg:w-2/12">
          <icon-button v-if="canEdit(u)" @click="editUser(u)" class="flex-shrink-0 mx-px" icon="edit" size="18"/>
        </div>
      </div>
    </div>
    <slide-pop
      v-if="isFormVisible"
      @close="toggleForm"
      :title="userForm.id ? 'Edit User' : 'Create User'"
    >
      <base-form-input label="Name" v-model="userForm.name"/>
      <base-form-input label="Email" v-model="userForm.email" type="email"/>
      <base-form-input label="Password" v-model="userForm.password" type="password"/>
      <base-form-select label="User Role" v-model="userForm.role" v-if="auth">
        <option value="na">Not Assigned</option>
        <option v-for="role in permission(auth.role)" :key="role" :value="role" class="capitalized">{{ capitalize(role) }}</option>
      </base-form-select>
      <template #controls="{ close }">
        <base-button class="block mb-2 font-medium bg-green-600" @click="saveUser(close)">
          {{ loading ? 'Loading...' : 'Save' }}
        </base-button>
        <base-button class="block mb-2" red v-if="canDelete(userForm)" @click="deleteUser(close)">Delete</base-button>
      </template>
    </slide-pop>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { models, useFind } from 'feathers-vuex'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SlidePop from '@/components/SlidePop'

const initialState = () => ({
  isFormVisible: false,
  userForm: {
    name: '',
    email: '',
    password: '',
    role: 'na'
  }
})

export default {
  name: 'Users',
  components: {
    SlidePop,
    Pagination,
    Search
  },
  setup() {
    const { User } = models.api

    const search = ref('')
    const pagination = ref({
      $limit: 10,
      $skip: 0
    })

    const fetchParams = computed(() => {
      const query = {
        name: {
          $like: '%' + search.value.trim() + '%'
        },
        $sort: {
          name: 1
        }
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'usersPage' }
    })

    const params = computed(() => {
      const query = {
        name: {
          $regex: search.value.trim()
        },
        $sort: {
          name: 1
        }
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'usersPage' }
    })

    const { items: users, latestQuery, isPending } = useFind({ model: User, params, fetchParams })

    return {
      users,
      pagination,
      latestQuery,
      isPending,
      search
    }
  },
  data() {
    return initialState()
  },
  computed: {
    ...mapGetters({
      auth: 'auth/user',
      isUserPendingById: 'users/isPendingById'
    }),
    loading() {
      return this.isUserPendingById(this.userForm.id)
    }
  },
  methods: {
    permission(role) {
      const roleMap = {
        super: ['super', 'admin', 'staff', 'user'],
        admin: ['staff', 'user'],
        staff: ['user']
      }
      return roleMap[role]
    },
    capitalize(str) {
      return str[0].toUpperCase() + str.slice(1)
    },
    canEdit(user) {
      return this.auth && (this.permission(this.auth.role).includes(user.role) || this.auth.id === user.id)
    },
    canDelete(user) {
      const roleMap = {
        super: ['super', 'admin', 'staff', 'user'],
        admin: ['user'],
        staff: []
      }
      return this.auth ? roleMap[this.auth.role].includes(user.role) : false
    },
    toggleForm() {
      this.isFormVisible = !this.isFormVisible
      if (!this.isFormVisible) {
        Object.assign(this.$data, initialState())
      }
    },
    saveUser(close) {
      const { User } = models.api
      const user = new User(this.userForm)
      if (!user.password) {
        this.$delete(user, 'password')
      }
      user.save().then(() => {
        close()
      })
    },
    editUser(user) {
      this.userForm = user.clone()
      this.toggleForm()
    },
    deleteUser(close) {
      if (confirm('Delete user?')) {
        const { User } = models.api
        const user = new User(this.userForm)
        user.remove().then(() => {
          close()
        })
      }
    }
  }
}
</script>
