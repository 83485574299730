export const categories = [
  { value: 4, title: 'Strollers' },
  { value: 2, title: 'Stroller Accessories' },
  { value: 2, title: 'Cold Weather Accessories' },
  { value: 4, title: 'Carriers' },
  { value: 3, title: 'Swings & Bouncers' },
  { value: 1, title: 'Travel' },
  { value: 2, title: 'Cribs & Dressers' },
  { value: 3, title: 'Bassinets' },
  { value: 2, title: 'Nursery Chairs, Rockers & Gliders' },
  { value: 3, title: 'Sleep Tech' },
  { value: 4, title: 'High Chairs' },
  { value: 4, title: 'Boosters' },
  { value: 1, title: 'Breast Pumps' },
  { value: 1, title: 'Feeding Pillows' },
  { value: 2, title: 'Meal Prep' },
  { value: 2, title: 'Toys' },
  { value: 3, title: 'Activity Gyms' },
  { value: 3, title: 'Playards' },
  { value: 2, title: 'Playmats' },
  { value: 1, title: 'Safety' },
  { value: 2, title: 'Hot Weather Accessories' },
  { value: 1, title: 'Bath Time' },
  { value: 2, title: 'Changing Accessories' },
  { value: 2, title: 'Decor' },
  { value: 1, title: 'Diaper Bags' },
  { value: 2, title: 'Storage' }
]

export const statusToText = {
  nc: 'Needs Confirmation',
  ap: 'Awaiting Pickup',
  ad: 'Awaiting Dropoff',
  do: 'Dropped Off',
  it: 'In Transit',
  ar: 'Archived'
}

export const statusToColor = {
  nc: '#E2D384',
  ap: '#9AE284',
  ad: '#9AE284',
  do: '#9AE284',
  it: '#9AE284',
  ar: '#489CC9'
}

export const ageToText = {
  4: '0-1 years old',
  3: '1-2 years old',
  2: '2-3 years old',
  1: '3-4 years old'
}

export const conditionToText = {
  4: 'Open Box',
  3: 'Excellent',
  2: 'Great',
  1: 'Good'
}

export const brandValueToText = {
  4: '4 - High',
  3: '3 - Medium',
  2: '2 - Normal',
  1: '1 - Low'
}

export function mapToValueText(obj) {
  return Object.entries(obj)
    .map(([key, value]) => ({ val: key, text: value }))
    .sort((a, b) => b.val - a.val)
}
