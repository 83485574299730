<template>
  <div>
    <base-floating-input
      :value="value"
      :label="label"
      @input="$emit('input', $event)"
      icon="search"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Search'
    }
  }
}
</script>
