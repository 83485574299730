<template>
  <div class="block relative" :class="{ 'my-6': !compact }">
    <label v-if="!compact" class="text-gray-700">Brand</label>
    <div
      class="flex items-center mt-1 w-full border shadow-sm focus-within:border-gray-700"
      :class="[required && !props.value > 0 ? 'border-red-600 ring ring-red-50' : compact ? 'text-sm border-gray-200' : 'text-xl border-gray-400 focus-within:ring focus-within:ring-gray-100', compact ? 'rounded' : 'rounded-md']"
    >
      <div v-if="selected" class="flex-1 px-3 select-none" :class="[compact ? 'py-0' : 'py-2']">
        {{ selected.name }}
      </div>
      <input
        v-else
        ref="inputRef"
        class="flex-1 flex-shrink-0 px-3 w-full bg-transparent border-none focus:ring-0 focus:outline-none"
        :class="[compact ? 'py-0' : 'py-2']"
        v-model="search"
        @focus="showDropdown = true"
        @blur="onBlurHandler"
        @keydown="handleKeydown"
        :disabled="disabled"
        :placeholder="compact ? 'Filter by Brand' : ''"
        name="Brand"
      />
      <icon-button
        :icon="search.length || selected ? 'x' : 'search'"
        class="flex-shrink-0 mx-0.5 text-gray-500"
        :class="{ 'h-8 w-8 -my-px': compact }"
        :size="compact ? '16' : '20'"
        @click="() => { search.length || selected ? clear() : onClickSearch() }"
      />
    </div>
    <div v-show="showDropdown && (search.length || showSuggested)" class="absolute left-0 z-10 my-4 min-w-full bg-white rounded-md border border-gray-200 shadow-lg outline-none" :class="[ compact ? 'top-6' : 'top-16' ]">
      <div class="overflow-y-auto py-1 wrapper" :class="[ showCreateForm ? 'max-h-64' : 'max-h-52' ]" ref="wrapperRef">
        <template v-if="!showCreateForm">
          <div
            class="flex px-4 leading-10 cursor-pointer option hover:bg-r-green-200 hover:bg-opacity-20"
            :class="{ 'bg-r-green-200 bg-opacity-20': index === selectedIndex }"
            :aria-selected="index === selectedIndex"
            :key="brand.id"
            v-for="(brand, index) in brands"
            @mousedown="onClickSelect(brand)"
          >
            <span class="flex-1">{{ brand.name }}</span>
            <span v-if="showSuggested" class="text-sm leading-10 text-r-green leading-2">Suggested</span>
          </div>
        </template>
        <template v-if="!compact && search.length && brands.length < 4">
          <div
            v-if="!showCreateForm"
            class="flex items-center px-4 font-bold leading-10 cursor-pointer text-r-green hover:bg-r-green-200 hover:bg-opacity-20"
            @mousedown.prevent="onClickAdd"
          >
            <icon name="plus" size="20"/>
            <span class="ml-2">Add brand "{{ search }}"</span>
          </div>
          <div v-else class="py-2 px-4">
            <base-form-input tight label="Brand Name" v-model="brandName" required/>
            <base-form-select tight label="Brand Value" v-model="brandValue" required :error="!brandValue > 0">
              <option :value="null" disabled selected hidden>Select Value</option>
              <option v-for="(item, index) in brandValueToTextOption" :key="index" :value="item.val">{{ item.text }}</option>
            </base-form-select>
            <div class="flex mt-4 mb-2">
              <base-button outline class="mr-2" @click="showCreateForm = false">Close</base-button>
              <base-button class="flex-1" :disabled="!isFormValid" @click="onClickCreate">Create</base-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, nextTick } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import { brandValueToText, mapToValueText } from '../utils'

export default {
  name: 'BrandPicker',
  props: {
    value: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    suggestion: {
      type: String,
      default: null
    },
    brand: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root, emit }) {
    const { Brand } = models.api

    const inputRef = ref(null)
    const showDropdown = ref(false)
    const search = ref('')

    const showCreateForm = ref(false)
    const brandName = ref('')
    const brandValue = ref(null)
    const brandValueToTextOption = mapToValueText(brandValueToText)

    const fetchParams = computed(() => {
      let query = {}
      if (search.value.length) {
        query = {
          name: { $iLike: '%' + search.value.trim() + '%' },
          $sort: { name: 1 }
        }
      } else if (props.suggestion) {
        query = {
          name: { $iLike: '%' + props.suggestion + '%' }
        }
      }
      if (props.value) {
        Object.assign(query, { id: props.value })
      }
      return { query, qid: 'brandsFetch' }
    })

    const params = computed(() => {
      let query = {}
      if (search.value.length) {
        query = {
          name: { $regex: search.value.trim(), $options: 'i' }
        }
      } else if (props.suggestion) {
        query = {
          name: { $regex: props.suggestion, $options: 'i' }
        }
      }
      if (props.value) {
        Object.assign(query, { id: props.value })
      }
      return { query, qid: 'brandsParams' }
    })

    const { items: brands, latestQuery, isPending } = useFind({ model: Brand, params, fetchParams, immediate: true })

    const selected = computed(() => {
      if (props.value && brands.value.length) {
        return brands.value[0]
      }
      if (props.value && props.brand) {
        return props.brand
      }
      return null
    })

    const showSuggested = computed(() => {
      return props.suggestion && brands.value.length === 1 && !search.value
    })

    const onClickSelect = (brand) => {
      search.value = brand.name
      emit('input', brand.id)
      showDropdown.value = false
    }

    const onClickSearch = () => {
      inputRef.value.focus()
    }

    const onBlurHandler = () => {
      if (!showCreateForm.value) {
        showDropdown.value = false
      }
    }

    const clear = () => {
      search.value = ''
      selectedIndex.value = -1
      emit('input', null)
    }

    watch(() => props.value, (val) => {
      if (!val) { clear() }
    })

    const wrapperRef = ref(null)
    const selectedIndex = ref(-1)
    const handleKeydown = (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        if (brands.value.length > 0 && selectedIndex.value < brands.value.length - 1) {
          selectedIndex.value++
          scrollToSelected()
        }
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (selectedIndex.value > -1) {
          selectedIndex.value--
          scrollToSelected()
        }
      }

      if (event.key === 'Enter') {
        event.preventDefault()
        if (selectedIndex.value > -1) {
          emit('input', brands.value[selectedIndex.value].id)
          showDropdown.value = false
        }
      }

      if (event.key === 'Escape') {
        showDropdown.value = false
        selectedIndex.value = -1
        search.value = ''
        inputRef.value.blur()
      }
    }

    const scrollToSelected = () => {
      nextTick(() => {
        if (wrapperRef.value) {
          wrapperRef.value.scrollTop = (selectedIndex.value * 40) - 80
        }
      })
    }

    const onClickAdd = () => {
      brandName.value = search.value.trim()
      showCreateForm.value = true
      showDropdown.value = true
    }

    const isFormValid = computed(() => {
      return brandName.value.length > 0 && brandValue.value > 0
    })

    const onClickCreate = () => {
      if (isFormValid) {
        const brand = new Brand({ name: brandName.value, value: brandValue.value })
        brand.save().then((b) => {
          onClickSelect(b)
          root.$store.commit('notify', { title: 'Success', text: `Brand "${b.name}" created`, type: 'success' })
        })
      }
    }

    return {
      selected,
      selectedIndex,
      showDropdown,
      onBlurHandler,
      search,
      inputRef,
      brands,
      latestQuery,
      isPending,
      onClickSelect,
      onClickSearch,
      onClickCreate,
      showCreateForm,
      isFormValid,
      onClickAdd,
      brandName,
      brandValue,
      brandValueToTextOption,
      showSuggested,
      handleKeydown,
      wrapperRef,
      clear,
      props
    }
  }
}
</script>
