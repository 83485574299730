import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import App from './App.vue'
import router from './router'
import store from './store'
import { socket } from './api/client'

import BaseButton from '@/components/BaseButton'
import IconButton from '@/components/IconButton'
import BaseFormInput from '@/components/BaseFormInput'
import BaseFormSelect from '@/components/BaseFormSelect'
import BaseFloatingInput from '@/components/BaseFloatingInput'
import BaseToggle from '@/components/BaseToggle'
import Icon from '@/components/Icon'

Vue.use(VueCompositionAPI)
Vue.config.productionTip = false

Vue.component('base-button', BaseButton)
Vue.component('icon-button', IconButton)
Vue.component('base-form-input', BaseFormInput)
Vue.component('base-form-select', BaseFormSelect)
Vue.component('base-floating-input', BaseFloatingInput)
Vue.component('base-toggle', BaseToggle)
Vue.component('icon', Icon)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    socket.on('rate-limit', (e) => {
      console.log('rate-limit', e)
      store.commit('notify', { title: 'Error: Too Many Request', type: 'alert' })
    })
    socket.on('disconnect', (e) => {
      console.log('Socket disconnected', e)
    })
  }
}).$mount('#app')
