<template>
  <div class="container px-4 pt-10 mx-auto md:px-8">
    <div class="flex items-start">
      <h1 class="flex-1">Drop-off Locations</h1>
      <base-button
        icon-right="calendar"
        :outline="!showSchedule"
        class="hidden flex-grow-0 mr-1 xl:flex"
        @click="showSchedule = !showSchedule"
      >
        Schedule
      </base-button>
      <base-button
        icon-right="plus"
        class="flex-grow-0 bg-green-600"
        @click="toggleForm"
      >
        Create
      </base-button>
    </div>
    <div class="flex flex-wrap items-center py-2">
      <search v-model="search" label="Search" class="w-full lg:w-5/12 lg:mr-auto"/>
      <div class="flex items-center w-full lg:w-auto lg:justify-end">
        <pagination v-model="pagination" :latest-query="latestQuery"/>
      </div>
    </div>
    <div v-if="showSchedule" class="flex items-center px-2 mb-2">
      <icon-button icon="chevron-left" class="ml-3" @click="prev"/>
      <icon-button icon="chevron-right" class="ml-1" @click="next"/>
      <div class="flex-1">
        <h2 class="inline-block ml-3 font-sans text-2xl font-bold">{{ viewTitle }}</h2>
      </div>
      <div class="flex">
        <div
          v-for="day in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
          :key="day"
          class="pt-2 mr-2 w-10 text-center text-gray-500"
        >
          {{ day }}
        </div>
      </div>
    </div>
    <div class="mb-12">
      <pickup-location-item
        v-for="item in items"
        :key="item.id"
        :item="item"
        :days="daysInView"
        :now="now"
        :show-schedule="showSchedule"
        @edit="selectItem(item)"
      />
    </div>
    <pickup-location-form
      v-if="isFormVisible"
      :data="formData"
      :is-editing="isEditing"
      @toggle-form="toggleForm"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import { format, isSameMonth, startOfWeek, endOfWeek, addDays, subDays } from 'date-fns'

import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import PickupLocationForm from '@/components/PickupLocationForm'
import PickupLocationItem from '@/components/PickupLocationItem'

const initialState = () => ({
  isFormVisible: false,
  isEditing: false,
  formData: {
    id: '',
    name: '',
    snum: '',
    street: '',
    city: '',
    postal: '',
    province: ''
  }
})

export default {
  name: 'PickupLocation',
  components: {
    Pagination,
    Search,
    PickupLocationForm,
    PickupLocationItem
  },
  setup() {
    const { PickupLocation } = models.api

    const search = ref('')
    const pagination = ref({
      $limit: 10,
      $skip: 0
    })

    const params = computed(() => {
      const query = {
        $sort: {
          id: 1
        }
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'pickupLocationParams' }
    })

    const { items, latestQuery, isPending } = useFind({ model: PickupLocation, params })

    return {
      search,
      pagination,
      items,
      latestQuery,
      isPending
    }
  },
  data() {
    return {
      ...initialState(),
      showSchedule: false,
      currentDate: new Date()
    }
  },
  computed: {
    now() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    viewTitle() {
      if (isSameMonth(this.currentStart, this.currentEnd)) {
        return format(this.currentDate, 'MMMM yyyy')
      } else {
        return format(this.currentStart, 'MMM') + ' - ' + format(this.currentEnd, 'MMM yyyy')
      }
    },
    currentStart() {
      return startOfWeek(this.currentDate)
    },
    currentEnd() {
      return endOfWeek(this.currentDate)
    },
    daysInView() {
      const days = []
      let day = this.currentStart
      while (day <= this.currentEnd) {
        days.push(day)
        day = addDays(day, 1)
      }
      return days.map(d => format(d, 'yyyy-MM-dd'))
    }
  },
  methods: {
    toggleForm() {
      this.isFormVisible = !this.isFormVisible
      if (!this.isFormVisible) {
        Object.assign(this.$data, initialState())
      }
    },
    selectItem(item) {
      this.isEditing = true
      this.formData = item.clone()
      this.toggleForm()
    },
    prev() {
      this.currentDate = subDays(this.currentDate, 7)
    },
    next() {
      this.currentDate = addDays(this.currentDate, 7)
    }
  }
}
</script>
