<template>
  <FeathersVuexPagination
    class="h-10"
    :value="value"
    :latest-query="latestQuery"
    @input="e => $emit('input', e)"
  >
    <template #default="{ currentPage, pageCount, toPage, next, prev, canNext, canPrev }">
      <div class="flex flex-row flex-1 items-center mt-2 text-sm text-gray-700">
        <div class="hidden ml-2 w-24 md:block">Total: {{ total }}</div>
        <div class="hidden mx-1 md:block">
          <select
            class="inline-block p-0 px-2 w-16 h-8 text-sm bg-white rounded border border-gray-200 shadow-sm focus:outline-none focus:border-gray-500 focus:ring-0"
            name="perPage" :value="value.$limit"
            title="Items Per Page"
            @input="e => $emit('input', { $limit: e.target.value, $skip: value.$skip })"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <div class="flex flex-1 items-center mx-1 lg:flex-initial">
          <input
            :value="currentPage"
            :min="1"
            :max="pageCount"
            title="Current Page"
            class="p-0 mr-2 w-12 h-8 text-sm text-center bg-white rounded border border-gray-200 shadow-sm focus:outline-none focus:border-gray-500"
            @input="event => toPage(event.target.value)"
          />
          of <span class="mx-2 w-6" title="Total Page">{{ pageCount }}</span>
        </div>
        <icon-button
          class="mx-px"
          icon="chevron-left"
          title="Previous Page"
          :disabled="!canPrev"
          @click="prev"
        />
        <icon-button
          class="mx-px"
          icon="chevron-right"
          title="Next Page"
          :disabled="!canNext"
          @click="next"
        />
      </div>
    </template>
  </FeathersVuexPagination>
</template>

<script>
import { FeathersVuexPagination } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

export default {
  name: 'Pagination',
  components: { FeathersVuexPagination },
  props: {
    value: {
      type: Object,
      required: true
    },
    latestQuery: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const total = computed(() => {
      const q = props.latestQuery
      if (q && q.response) {
        return q.response.total
      } else {
        return 0
      }
    })

    return {
      total
    }
  }
}
</script>
