<template>
  <div class="container px-4 mx-auto pt-10 md:px-8">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>
