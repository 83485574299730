<template>
  <button
    v-on="$listeners"
    class="button"
    :class="{
      'bg-opacity-50 hover:bg-opacity-50 cursor-not-allowed': disabled,
      'text-gray-700 border-gray-400 bg-transparent hover:bg-gray-100': outline,
      'text-red-600 border-red-600 bg-transparent hover:bg-red-50': red
    }"
    :disabled="disabled"
  >
    <icon v-if="iconLeft" :name="iconLeft" size="18" class="ml-1"/>
    <span class="overflow-x-hidden flex-shrink-0 mx-2 whitespace-nowrap overflow-ellipsis"><slot></slot></span>
    <icon v-if="iconRight" :name="iconRight" size="18" class="mr-1"/>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    disabled: Boolean,
    outline: Boolean,
    red: Boolean,
    iconLeft: String,
    iconRight: String
  }
}
</script>
