<template>
  <div class="flex justify-between items-center group" @click="$emit('input', !value)">
    <p v-if="!after" class="flex-1 select-none" v-text="label"></p>
    <div class="flex flex-shrink-0 items-center p-0.5 w-9 h-5 bg-gray-300 group-hover:bg-gray-200 rounded-full duration-100 ease-in-out" :class="{ 'bg-green-500 group-hover:bg-green-400': value }">
      <div class="w-4 h-4 bg-white rounded-full shadow-md duration-100 ease-in-out transform group-hover:shadow" :class="{ 'translate-x-4': value }"></div>
    </div>
    <p v-if="after" class="overflow-x-hidden flex-1 ml-3 select-none overflow-ellipsis" v-text="label"></p>
  </div>
</template>

<script>
export default {
  name: 'BaseToggle',
  props: {
    value: {
      type: Boolean
    },
    label: {
      type: String
    },
    after: {
      type: Boolean,
      default: false
    }
  }
}
</script>
