<template>
  <div class="container px-4 pt-10 mx-auto md:px-8">
    <div class="flex items-start">
      <h1 class="flex-1">Brands Database</h1>
      <base-button
        v-if="!isStaff"
        icon-right="plus"
        class="flex-grow-0 py-1 bg-green-600 md:py-2"
        @click="toggleForm"
      >
        Create
      </base-button>
    </div>
    <p class="mt-3 text-lg">Brands are used by the Product Estimator service to categorize by brand.</p>
    <div class="flex flex-wrap items-center py-2">
      <search v-model="search" label="Find a brand" class="w-full lg:w-5/12 lg:mr-auto"/>
      <div class="flex items-center w-full lg:w-auto lg:justify-end">
        <pagination v-model="pagination" :latest-query="latestQuery"/>
      </div>
    </div>
    <div class="mb-12">
      <div
        v-for="brand in brands"
        :key="brand.id"
        class="flex flex-wrap p-2 mb-2 bg-white rounded-md border border-gray-200 shadow hover:border-gray-400"
      >
        <div class="w-full lg:flex-1">
          <p class="pl-3 text-lg leading-10">{{ brand.name }}</p>
        </div>
        <div class="flex-auto pl-3 lg:flex-initial lg:w-32 lg:mx-4">
          <p>{{ brand.value ? brandValueToText(brand.value) : '–' }}</p>
          <p class="text-sm text-gray-500">Brand Value</p>
        </div>
        <div class="flex justify-end items-center" v-if="!isStaff">
          <icon-button @click="editBrand(brand)" class="flex-shrink-0 mx-px" icon="edit" size="18"/>
        </div>
      </div>
    </div>
    <slide-pop
      v-if="isFormVisible"
      @close="toggleForm"
      :title="brandForm.id ? 'Edit Brand' : 'Add Brand'"
    >
      <base-form-input label="Name" v-model="brandForm.name" :error="!brandForm.name.length > 0"/>
      <base-form-select label="Brand Value" v-model="brandForm.value" required :error="!brandForm.value > 0">
        <option :value="null" disabled selected hidden>Select Value</option>
        <option v-for="(item, index) in brandValueToTextOption" :key="index" :value="item.val">{{ item.text }}</option>
      </base-form-select>
      <base-form-input label="Website" v-model="brandForm.website"/>
      <base-form-input label="Logo" v-model="brandForm.logo"/>
      <p v-if="error" class="text-red-600">
        <span class="block">Error {{ error.message }}</span>
        <pre>{{ error.errors[0] }}</pre>
      </p>
      <template #controls="{ close }">
        <base-button class="block mb-2 font-medium bg-green-600" @click="saveBrand(close)" :disabled="!isFormValid">Save</base-button>
        <base-button v-if="brandForm.id" class="block" red @click="deleteBrand(close)">Delete</base-button>
      </template>
    </slide-pop>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SlidePop from '@/components/SlidePop'
import { brandValueToText, mapToValueText } from '../utils'

const initialState = () => ({
  isFormVisible: false,
  brandForm: {
    name: '',
    value: null,
    website: null,
    logo: null
  },
  error: null
})

export default {
  name: 'Brands',
  components: {
    Pagination,
    Search,
    SlidePop
  },
  setup() {
    const { Brand } = models.api

    const search = ref('')
    const pagination = ref({
      $limit: 10,
      $skip: 0
    })

    const fetchParams = computed(() => {
      const query = {
        name: { $iLike: '%' + search.value.trim() + '%' },
        $sort: { name: 1 }
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'brandsFetch' }
    })

    const params = computed(() => {
      const query = {
        name: { $regex: search.value.trim(), $options: 'i' },
        $sort: { name: 1 }
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'brandsLocal' }
    })

    const { items: brands, latestQuery } = useFind({ model: Brand, params, fetchParams })

    return {
      brands,
      pagination,
      latestQuery,
      search
    }
  },
  data() {
    return initialState()
  },
  computed: {
    isStaff() {
      return this.$store.getters.isStaff
    },
    isFormValid() {
      return this.brandForm.name.length > 0 && this.brandForm.value > 0
    },
    brandValueToTextOption() {
      return mapToValueText(brandValueToText)
    }
  },
  methods: {
    brandValueToText(n) {
      return brandValueToText[n]
    },
    toggleForm() {
      this.isFormVisible = !this.isFormVisible
      if (!this.isFormVisible) {
        Object.assign(this.$data, initialState())
      }
    },
    editBrand(brand) {
      this.brandForm = brand.clone()
      this.toggleForm()
    },
    saveBrand(close) {
      if (this.isFormValid) {
        const { Brand } = models.api
        const brand = new Brand(this.brandForm)
        brand.save().then(() => {
          close()
        }).catch((error) => {
          this.error = error
        })
      }
    },
    deleteBrand(close) {
      if (confirm('Delete brand?')) {
        const { Brand } = models.api
        const brand = new Brand(this.brandForm)
        brand.remove().then(() => {
          close()
        })
      }
    }
  }
}
</script>
