<template>
  <label class="block relative my-4">
    <span
      class="flex absolute bottom-1 z-0 items-center text-xl transition-transform duration-300 select-none origin-0"
      :class="{ 'transform scale-75 -translate-y-6 opacity-50': focus || value }"
    >
      <icon class="mr-2" v-if="icon" size="18" :name="icon"/>
      <span>{{ label }}</span>
    </span>
    <input
      class="block px-0 pt-3 pb-1 mt-0 w-full text-xl bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:ring-0 focus:border-r-dark focus:outline-none"
      :type="type"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @focus="focus=true"
      @blur="focus=false"
    >
  </label>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    label: String,
    icon: String,
    value: String
  },
  data () {
    return {
      focus: false
    }
  }
}
</script>
