import feathersClient, { makeServicePlugin, BaseModel } from '../../api/client'

class Product extends BaseModel {
  static modelName = 'Product'
  static instanceDefaults() {
    return {
      name: '',
      brandId: null,
      value: 0,
      role: 'na'
    }
  }

  static setupInstance(data, { models }) {
    if (data.brand) {
      data.brand = new models.api.Brand(data.brand)
    }
    return data
  }
}
const servicePath = 'intel/products'
const servicePlugin = makeServicePlugin({
  Model: Product,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
