import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '../api/client'
import auth from './store.auth'

Vue.use(Vuex)
Vue.use(FeathersVuex)

const req = require.context('./services', false, /\.js$/)
const servicePlugins = req.keys().map(modulePath => req(modulePath).default)

export default new Vuex.Store({
  state: {
    notifications: [],
    isMenuOpen: true
  },
  getters: {
    region: (state, getters) => {
      return state.auth?.user ? getters['auth/user'].settings?.region : 'gta'
    },
    isStaff: (state, getters) => {
      return state.auth?.user && getters['auth/user'].role === 'staff'
    }
  },
  mutations: {
    notify: (state, {
      id = new Date().toISOString(),
      title = '',
      text = null,
      type = 'default',
      timeout = true // auto dismiss
    }) => {
      state.notifications.push({ id, title, type, text, timeout })
    },
    removeNotification: (state, { id }) => {
      const index = state.notifications.findIndex(n => n.id === id)
      if (index > -1) {
        Vue.delete(state.notifications, index)
      }
    },
    toggleMenu: (state, bool = null) => {
      if (bool !== null) {
        state.isMenuOpen = bool
      } else {
        state.isMenuOpen = !state.isMenuOpen
      }
    }
  },
  actions: {},
  plugins: [...servicePlugins, auth]
})
