<template>
  <span class="ml-2" title="Change Date">
    <base-button outline icon-left="file-text" size="16" @click="onClickOpen" class="mr-2">Local Delivery</base-button>
    <modal v-if="isOpen" @close="isOpen = false">
      <div class="py-3 px-5 lg:px-12 lg:py-10">
        <h1>List of Local Delivery</h1>
        <div class="py-2 px-3 mt-6 font-mono bg-gray-50 rounded-lg border select-all">
          <icon v-if="loading" name="loader" class="animate-spin"/>
          <span>{{ postalList }}</span>
        </div>
      </div>
    </modal>
  </span>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex'
import Modal from '@/components/Modal'

export default {
  name: 'PostalLocalModal',
  components: { Modal },
  mixins: [
    makeFindMixin({ service: 'postal', local: true })
  ],
  data() {
    return {
      isOpen: false,
      loading: true,
      pagination: {
        $limit: 50,
        $skip: 0
      }
    }
  },
  computed: {
    postalParams() {
      return {
        query: { $sort: { id: 1 } },
        paginate: false
      }
    },
    postalList() {
      return this.postal
        .filter(p => p.service.includes('LocalSell'))
        .map(p => p.id + '*')
        .join(', ')
    }
  },
  methods: {
    onClickOpen() {
      this.isOpen = true
      this.loadAll()
    },
    async loadAll() {
      const { $limit, $skip } = this.pagination
      await this.findPostal({ query: { $limit, $skip } })
      while (this.postalLatestQuery.response.total > ($limit + this.pagination.$skip)) {
        this.pagination.$skip = $limit + this.pagination.$skip
        await this.findPostal({
          query: { $limit, $skip: this.pagination.$skip }
        })
      }
      this.loading = false
    }
  }
}
</script>
