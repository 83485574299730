<template>
  <div class="container px-4 pt-10 mx-auto md:px-8">
    <div class="flex items-start">
      <h1 class="flex-1">Products Database</h1>
      <base-button
        v-if="!isStaff"
        icon-right="plus"
        class="flex-grow-0 py-1 bg-green-600 md:py-2"
        @click="toggleForm"
      >
        Create
      </base-button>
    </div>
    <p class="mt-3 text-lg">The product database stores a present list of items which are used for the pricing algorithm and for autofilling during cataloging.</p>
    <div class="flex flex-wrap items-center py-2">
      <search v-model="search" label="Find a product" class="w-full lg:w-1/3 lg:mr-auto"/>
      <div class="flex flex-wrap items-center lg:flex-nowrap lg:w-auto lg:justify-end">
        <div class="relative flex-shrink-0 mt-1 mr-1 w-full sm:w-40 lg:w-48 lg:mr-2">
          <brand-picker compact v-model="brandFilter"/>
        </div>
        <div class="mt-2 mr-1 lg:mr-2">
          <select
            class="inline-block py-0 px-2 w-32 h-8 text-sm bg-white rounded border border-gray-200 shadow-sm focus:outline-none focus:border-gray-500 focus:ring-0"
            name="Filter"
            v-model="filter"
            @change="resetFilter"
          >
            <option :value="null">All Items</option>
            <option value="a">Approved</option>
            <option value="na">Not Approved</option>
            <option value="del">Deleted</option>
          </select>
        </div>
        <div class="mt-2 mr-1 lg:mr-8">
          <select
            title="Currency"
            class="inline-block py-0 px-2 w-24 h-8 text-sm bg-white rounded border border-gray-200 shadow-sm focus:outline-none focus:border-gray-500 focus:ring-0"
            name="Filter"
            v-model="currencyFilter"
            @change="resetFilter"
          >
            <option :value="null">All</option>
            <option value="CAD">CAD</option>
            <option value="USD">USD</option>
          </select>
        </div>
        <pagination v-model="pagination" :latest-query="latestQuery"/>
      </div>
    </div>
    <transition
      mode="out-in"
      enter-active-class="transition-all"
      leave-active-class="transition-all"
      enter-class="duration-500"
      leave-to-class="duration-100"
    >
      <div v-if="!isPending" class="mb-12" :key="1">
        <product
          v-for="product in products"
          :key="product.id"
          :filter="filter"
          :product="product"
          @edit="editProduct(product)"
          @toggle-approval="editProductApproval(product)"
        />
      </div>
      <div v-else class="mb-12" :key="2">
        <product v-for="n in parseInt(pagination.$limit)" :key="n" :loading="isPending"/>
      </div>
    </transition>
    <product-form
      v-if="isFormVisible"
      :data="productForm"
      @toggle-form="toggleForm"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { models, useGet, useFind } from 'feathers-vuex'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import Product from '@/components/Product'
import ProductForm from '@/components/ProductForm'
import BrandPicker from '@/components/BrandPicker'

const initialState = () => ({
  isFormVisible: false,
  productForm: {
    name: '',
    brandId: null,
    category: null,
    value: 0,
    role: 'na',
    info: {
      currency: 'CAD',
      scraper: false,
      pulledBrand: null,
      pulledFrom: null,
      pulledOn: null
    }
  }
})

export default {
  name: 'Products',
  components: {
    Pagination,
    Search,
    BrandPicker,
    Product,
    ProductForm
  },
  setup() {
    const { Product, Brand } = models.api

    const search = ref('')
    const brandFilter = ref(null)
    const currencyFilter = ref(null)
    const filter = ref(null)
    const pagination = ref({
      $limit: 10,
      $skip: 0
    })

    const { item: brand } = useGet({ model: Brand, id: brandFilter })

    const fetchParams = computed(() => {
      const query = {
        name: { $iLike: '%' + search.value.trim() + '%' },
        role: { $ne: 'del' },
        $sort: { name: 1, id: 1 }
      }
      Object.assign(query, pagination.value)
      if (filter.value) { Object.assign(query, { role: filter.value }) }
      if (brandFilter.value && brand.value) {
        Object.assign(query, {
          $or: [
            { brandId: brandFilter.value },
            { 'info.pulledBrand': { $iLike: '%' + escape(brand.value.name) + '%' } }
          ]
        })
      }
      if (currencyFilter.value) {
        Object.assign(query, { 'info.currency': currencyFilter.value })
      }
      return { query, qid: 'productsFetch' }
    })

    const params = computed(() => {
      const query = {
        name: { $regex: search.value, $options: 'i' },
        role: { $ne: 'del' },
        $sort: { name: 1, id: 1 }
      }
      Object.assign(query, pagination.value)
      if (filter.value) { Object.assign(query, { role: filter.value }) }
      if (brandFilter.value && brand.value) {
        Object.assign(query, {
          $or: [
            { brandId: brandFilter.value },
            { 'info.pulledBrand': { $regex: brand.value.name, $options: 'i' } }
          ]
        })
      }
      if (currencyFilter.value) {
        Object.assign(query, { 'info.currency': currencyFilter.value })
      }
      return { query, qid: 'productsLocal' }
    })

    const { items: products, latestQuery, isPending } = useFind({ model: Product, params, fetchParams, immediate: true })

    return {
      products,
      fetchParams,
      params,
      pagination,
      latestQuery,
      isPending,
      find,
      search,
      brandFilter,
      currencyFilter,
      filter
    }
  },
  data() {
    return initialState()
  },
  computed: {
    isStaff() {
      return this.$store.getters.isStaff
    }
  },
  methods: {
    toggleForm() {
      this.isFormVisible = !this.isFormVisible
      if (!this.isFormVisible) {
        Object.assign(this.$data, initialState())
      }
    },
    resetFilter() {
      // this.brandFilter = null
      this.pagination.$skip = 0
    },
    editProduct(product) {
      if (this.isStaff) { return }
      this.productForm = product.clone()
      this.toggleForm()
    },
    editProductApproval(product) {
      product.role = product.role === 'na' ? 'a' : 'na'
      product.patch()
    }
  }
}
</script>
