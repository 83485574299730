<template>
  <slide-pop
    wide
    :title="isEditing ? 'Edit Location' : 'Add Location'"
    @close="$emit('toggle-form')"
    @shown="showMap"
  >
    <p class="mb-6">You can search address to edit location, and manage schedule.</p>
    <div class="flex mt-4 h-96">
      <div id="map" class="w-full h-full"></div>
    </div>
    <div class="grid gap-x-4 mt-4 lg:grid-cols-2">
      <base-form-input tight label="Store Number" v-model="formData.id" placeholder="PPU001"/>
      <base-form-input tight label="Location Name" v-model="formData.name"/>
      <base-form-input tight label="Street Number" disabled v-model="formData.snum"/>
      <base-form-input tight label="Street Name" disabled v-model="formData.street"/>
      <base-form-input tight label="City" disabled v-model="formData.city"/>
      <base-form-input tight label="Postal" disabled v-model="formData.postal"/>
      <base-form-input tight label="Province" disabled v-model="formData.province"/>
      <base-form-select tight label="Country" v-model="country">
        <option value="CA">CA</option>
        <option value="US">US</option>
      </base-form-select>
      <!-- <base-form-input tight label="Coordinates" disabled :value="`${coordinates[0]}, ${coordinates[1]}`"/> -->
    </div>
    <div class="grid gap-x-4 mb-6 lg:grid-cols-2">
      <div>
        <h3 class="mb-2 text-gray-700">Operating Hours</h3>
        <div class="grid overflow-hidden mb-6 bg-gray-300 rounded-md border border-gray-300">
          <div v-for="d in days" :key="d[0]" class="flex overflow-hidden py-px pr-1.5 pl-3 w-full text-lg leading-9 bg-white space-between">
            <div class="overflow-hidden flex-1 min-w-0">
              <base-toggle after :label="d[1]" :value="selectedHour(d[0])" @input="e => selectHours(d[0], e)"/>
            </div>
            <div class="flex flex-shrink-0 items-center" v-if="selectedHour(d[0])">
              <input
                class="px-1 w-14 text-base tabular-nums leading-7 text-center text-gray-500 bg-transparent rounded border-none focus:text-gray-800 focus:bg-gray-100 hover:bg-gray-50 focus:outline-none"
                :value="getHour(d[0], 'open')"
                @input="e => setHour(d[0], 'open', e)"
              />
              <span class="mx-px text-gray-500">–</span>
              <input
                class="px-1 w-14 text-base tabular-nums leading-7 text-center text-gray-500 bg-transparent rounded border-none focus:text-gray-800 focus:bg-gray-100 hover:bg-gray-50 focus:outline-none"
                :value="getHour(d[0], 'close')"
                @input="e => setHour(d[0], 'close', e)"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <base-toggle class="mb-2 text-gray-700" :label="showDropoff ? 'Drop-off Schedules (read-only)' : 'Pickup Schedules'" v-model="showDropoff"/>
        <v-calendar
          is-expanded
          title-position="left"
          color="green"
          :attributes="attributes"
          @dayclick="onDayClick"
          :readonly="showDropoff"
        />
      </div>
    </div>
    <p v-if="formError" class="mb-10 text-red-600">Error: {{ formError }}</p>
    <template #controls="{ close }">
      <div class="flex justify-end">
        <base-button outline v-if="marker" class="mr-auto" @click="clear">Reset Map</base-button>
        <base-button v-if="isEditing" red class="ml-auto" @click="deleteItem(close)">Delete</base-button>
        <base-button class="ml-2 w-48 font-medium bg-green-600" @click="saveItem(close)">Save</base-button>
      </div>
    </template>
  </slide-pop>
</template>

<script>
import { models } from 'feathers-vuex'
import { getDay, parseISO, subDays } from 'date-fns'
import SlidePop from '@/components/SlidePop'

import { geocoder, mapboxgl } from '@/services/mapbox'
import Calendar from 'v-calendar/lib/components/calendar.umd'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { format } from 'date-fns/esm'

export default {
  props: ['data', 'isEditing'],
  components: {
    'v-calendar': Calendar,
    SlidePop
  },
  data() {
    return {
      days: [
        [0, 'Sunday'],
        [1, 'Monday'],
        [2, 'Tuesday'],
        [3, 'Wednesday'],
        [4, 'Thursday'],
        [5, 'Friday'],
        [6, 'Saturday']
      ],
      coordinates: [-79.3849, 43.6529],
      hours: [],
      schedule: [],
      country: 'CA',
      showDropoff: false,
      formError: null,
      zoom: 12,
      map: {},
      marker: null
    }
  },
  computed: {
    formData() {
      return this.data
    },
    getHour() {
      return (d, attr) => this.hours.find(h => h.day === d)[attr]
    },
    dates() {
      if (!this.showDropoff) return this.schedule.map(day => day.date)
      return this.schedule.map(day => day.date)
        .map(d => format(subDays(d, 1), 'yyyy-MM-dd'))
        .filter(d => this.hours.map(h => h.day).includes(getDay(parseISO(d))))
    },
    attributes() {
      return [
        ...this.dates.map(date => ({
          highlight: {
            style: {
              backgroundColor: this.showDropoff ? '#9CA3AF' : '#10B981'
            },
            contentStyle: {
              color: 'white',
              fontWeight: 'bold'
            }
          },
          dates: date
        })),
        {
          dot: 'red',
          dates: new Date()
        }
      ]
    }
  },
  mounted() {
    if (this.isEditing && this.formData.ancillary) {
      this.hours = this.formData.ancillary.hours
      this.coordinates = this.formData.ancillary.coordinates
      this.country = this.formData.ancillary.country ?? 'CA'
      this.schedule = this.formData.ancillary.schedule
        .map(date => ({ id: date, date: parseISO(date) }))
    }
  },
  methods: {
    showMap() {
      this.$nextTick(() => { this.createMap() })
    },
    async createMap() {
      try {
        if (this.isEditing) {
          this.zoom = 16
        }

        this.map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/mapbox/streets-v11',
          center: this.coordinates,
          zoom: this.zoom
        })

        this.map.addControl(geocoder)

        if (this.isEditing) {
          this.marker = new mapboxgl.Marker({
            color: '#D80739'
          })
            .setLngLat(this.coordinates)
            .addTo(this.map)
          this.marker.on('dragend', (e) => {
            this.coordinates = Object.values(e.target.getLngLat())
          })
        }

        geocoder.on('result', (e) => {
          this.marker?.remove()
          this.marker = new mapboxgl.Marker({
            draggable: true,
            color: '#D80739'
          })
            .setLngLat(e.result.center)
            .addTo(this.map)
          this.formData.snum = e.result.address
          this.formData.street = e.result.text
          this.formData.city = e.result.context.find(c => c.id.split('.')[0] === 'place').text
          this.formData.postal = e.result.context.find(c => c.id.split('.')[0] === 'postcode').text
          this.formData.province = e.result.context.find(c => c.id.split('.')[0] === 'region').short_code.split('-')[1]
          this.formData.country = e.result.context.find(c => c.id.split('.')[0] === 'country').short_code.toUpperCase()
          this.coordinates = e.result.center
          this.marker.on('dragend', (e) => {
            this.coordinates = Object.values(e.target.getLngLat())
          })
        })
      } catch (err) {
        console.log('map error', err)
      }
    },
    clear() {
      this.marker.remove()
      this.marker = null
      this.formData.snum = ''
      this.formData.street = ''
      this.formData.city = ''
      this.formData.postal = ''
      this.formData.province = ''
      this.country = ''
      geocoder.clear()
    },
    selectedHour(num) {
      return this.hours.findIndex(h => h.day === num) > -1
    },
    selectHours(num, bool) {
      if (bool) {
        this.hours.push({ day: num, open: '09:00', close: '17:00' })
      } else {
        const i = this.hours.findIndex(h => h.day === num)
        this.$delete(this.hours, i)
      }
      this.hours.sort((a, b) => a.day - b.day)
    },
    setHour(d, attr, e) {
      this.hours.find(h => h.day === d)[attr] = e.target.value
    },
    onDayClick(day) {
      if (this.showDropoff) return
      const idx = this.schedule.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        this.schedule.splice(idx, 1)
      } else {
        this.schedule.push({
          id: day.id,
          date: day.date
        })
      }
    },
    saveItem(close) {
      const { PickupLocation } = models.api
      const loc = new PickupLocation({
        ...this.formData,
        ancillary: {
          hours: this.hours,
          coordinates: this.coordinates,
          schedule: this.schedule.map(date => date.id),
          country: this.country
        }
      })
      loc.save()
        .then(() => close())
        .catch(err => {
          if (err.code === 404) {
            loc.create()
              .then(() => close())
              .catch(err => {
                this.formError = err.message
              })
          } else {
            this.formError = err.message
          }
        })
    },
    deleteItem(close) {
      if (confirm('Delete location?')) {
        const { PickupLocation } = models.api
        const loc = new PickupLocation(this.formData)
        loc.remove().then(() => {
          close()
        })
      }
    }
  }
}
</script>
