import feathersClient, { makeServicePlugin, BaseModel } from '../../api/client'

class PickupLocation extends BaseModel {
  static modelName = 'PickupLocation'
}
const servicePath = '/logistics/pickup-locations'
const servicePlugin = makeServicePlugin({
  Model: PickupLocation,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
