<template>
  <slide-pop
    :title="isEditing ? 'Edit Service Area' : 'Create Service Area'"
    @close="$emit('toggle-form')"
  >
    <base-form-input label="Postal Code" v-model="formData.id" placeholder="M1M"/>
    <base-form-input label="City" v-model="city"/>
    <base-form-input label="Suburb" v-model="suburb" optional/>
    <base-form-select label="Region" v-model="formData.tag">
      <option value="gta">Toronto</option>
      <option value="van">Vancouver</option>
      <option value="nyc">New York</option>
    </base-form-select>
    <h3 class="text-gray-700">Service</h3>
    <div class="grid py-1 mt-1 mb-6 rounded-md border border-gray-300">
      <div v-for="label in labels" :key="label" class="flex py-px px-3 w-full text-lg leading-9">
        <base-toggle after :label="label" :value="selected(label)" @input="toggleLabel(label)"/>
      </div>
    </div>
    <h3 class="mb-1 text-gray-700">Pickup Schedules</h3>
    <v-calendar
      is-expanded
      title-position="left"
      color="green"
      :attributes="attributes"
      @dayclick="onDayClick"
    />
    <p v-if="formError" class="mb-10 text-red-600">Error: {{ formError }}</p>
    <template #controls="{ close }">
      <base-button class="block mt-6 mb-2 font-medium bg-green-600" @click="saveItem(close)">Save</base-button>
      <base-button v-if="isEditing" red class="block" @click="deleteItem(close)">Delete</base-button>
    </template>
  </slide-pop>
</template>

<script>
import { models } from 'feathers-vuex'
import { parseISO } from 'date-fns'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import SlidePop from '@/components/SlidePop'

export default {
  props: {
    data: Object,
    isEditing: Boolean
  },
  data() {
    return {
      labels: [
        'Dropoff',
        'Pickup',
        'Delivery',
        'LocalSell'
      ],
      schedule: [],
      formError: null
    }
  },
  components: {
    SlidePop,
    'v-calendar': Calendar
  },
  computed: {
    formData() {
      return this.data
    },
    city: {
      get() {
        return this.formData.city?.split('/')[0]
      },
      set(val) {
        this.formData.city = this.suburb ? val + '/' + this.suburb : val
      }
    },
    suburb: {
      get() {
        return this.formData.city?.split('/')[1] ?? this.formData.suburb
      },
      set(val) {
        this.formData.city = val ? this.city + '/' + val : this.city
      }
    },
    dates() {
      return this.schedule.map(day => day.date)
    },
    attributes() {
      return [
        ...this.dates.map(date => ({
          highlight: {
            style: {
              backgroundColor: '#10B981'
            },
            contentStyle: {
              color: 'white',
              fontWeight: 'bold'
            }
          },
          dates: date
        })),
        {
          dot: 'red',
          dates: new Date()
        }
      ]
    }
  },
  mounted() {
    if (this.isEditing && this.formData.ancillary?.schedule) {
      this.schedule = this.formData.ancillary.schedule
        .map(date => ({ id: date, date: parseISO(date) }))
    }
  },
  methods: {
    onDayClick(day) {
      const idx = this.schedule.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        this.schedule.splice(idx, 1)
      } else {
        this.schedule.push({
          id: day.id,
          date: day.date
        })
      }
    },
    selected(label) {
      return this.formData.service.includes(label)
    },
    toggleLabel(label) {
      if (this.selected(label)) {
        this.formData.service = this.formData.service.filter(s => s !== label)
      } else {
        this.formData.service.push(label)
      }
    },
    saveItem(close) {
      const { Postal } = models.api
      const postal = new Postal({
        ...this.formData,
        ancillary: {
          ...this.formData.ancillary,
          schedule: this.schedule.map(date => date.id)
        }
      })
      postal.save()
        .then(() => close())
        .catch(err => {
          if (err.code === 404) {
            postal.create()
              .then(() => close())
              .catch(err => {
                this.formError = err.message
              })
          } else {
            this.formError = err.message
          }
        })
    },
    deleteItem(close) {
      if (confirm('Delete postal?')) {
        const { Postal } = models.api
        const postal = new Postal(this.formData)
        postal.remove().then(() => {
          close()
        })
      }
    }
  }
}
</script>
