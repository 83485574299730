<template>
  <transition
    appear
    appear-class="opacity-0"
    appear-active-class="transition-all ease-in"
    leave-to-class="opacity-0"
    leave-active-class="transition-all ease-in"
    @appear="showContent = true"
  >
    <div class="overflow-y-auto overflow-x-hidden fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-60" @mousedown="showContent = false">
      <transition
        enter-active-class="transition ease-out transform"
        enter-class="translate-y-full"
        enter-to-class="translate-y-0"
        leave-active-class="transition ease-in transform"
        leave-class="translate-y-0"
        leave-to-class="translate-y-full"
        @before-leave="$emit('close')"
      >
        <div
          v-if="showContent"
          class="flex relative flex-col mx-auto mt-36 mb-40 w-full bg-white rounded-xl shadow-lg transition-all duration-300"
          :style="`max-width: ${width}px; min-height: ${height}px`"
          @mousedown.stop
        >
          <icon-button icon="x" size="20" @click.prevent="close" class="absolute top-1 right-1"/>
          <slot :close="close"></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      showContent: false
    }
  },
  created() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        this.$emit('close')
      }
    }
    document.addEventListener('keydown', escapeHandler)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  },
  methods: {
    close() {
      this.showContent = false
    }
  }
}
</script>
