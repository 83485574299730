<template>
  <div class="flex p-2 mb-2 bg-white rounded-md border border-gray-200 shadow hover:border-gray-400">
    <div class="flex-1">
      <p class="py-2 pl-3 text-lg leading-6"><span class="font-bold">{{ item.id }} {{ item.name }}</span> {{ item.snum }} {{ item.street }}, {{ item.city }}, {{ item.province }}</p>
    </div>
    <div v-if="!showSchedule" class="flex justify-end items-center">
      <icon-button class="flex-shrink-0 mx-px" icon="edit" size="18" @click="$emit('edit')"/>
    </div>
    <div v-else class="flex flex-shrink-0">
      <div v-for="(day, index) in days" :key="index" class="relative mr-2">
        <button
          class="font-bold icon-button"
          :class="{'bg-green-500 text-white hover:bg-green-400 hover:text-white': selected(day) }"
          @click="toggleDate(day)"
        >
          {{ getNumeric(day) }}
        </button>
        <div v-if="day === now" class="block absolute -bottom-1 left-4 w-1.5 h-1.5 ml-px bg-red-500 rounded"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'

export default {
  props: {
    item: Object,
    now: String,
    showSchedule: Boolean,
    days: Array
  },
  methods: {
    getNumeric(date) {
      return date.split('-')[2]
    },
    selected(date) {
      return this.item.ancillary?.schedule?.includes(date) ?? false
    },
    toggleDate(date) {
      const { PickupLocation } = models.api
      const loc = new PickupLocation(this.item)
      if (this.selected(date)) {
        loc.ancillary.schedule = loc.ancillary.schedule.filter(d => d !== date)
      } else {
        loc.ancillary.schedule.push(date)
      }
      loc.save()
    }
  }
}
</script>
