<template>
  <div class="block relative" :class="[ tight ? 'mb-2' : 'my-6' ]">
    <label class="flex items-baseline text-gray-800">
      {{ label }}
      <span v-if="optional" class="ml-auto text-xs tracking-wider text-gray-500 uppercase">Optional</span>
    </label>
    <div
      class="flex items-center mt-1 rounded-md border shadow-sm focus-within:border-gray-700 focus-within:ring focus-within:ring-gray-100"
      :class="[ error ? 'border-red-600 ring ring-red-50' : disabled ? 'border-gray-300' : 'border-gray-400' ]"
    >
      <icon v-if="icon" :name="icon" class="flex-shrink-0 ml-3 text-gray-500 select-none" size="20"/>
      <input
        class="flex-1 text-xl placeholder-gray-400 bg-transparent border-none focus:ring-0"
        :class="{ 'text-gray-500': disabled }"
        :type="type"
        :value="value"
        :autocomplete="type === 'password' ? 'new-password' : 'false'"
        :disabled="disabled"
        :title="disabled ? 'Read-only' : ''"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    value: [String, Number],
    optional: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    tight: {
      type: Boolean,
      default: false
    }
  }
}
</script>
