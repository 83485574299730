<template>
  <button
    v-on="$listeners"
    class="icon-button"
    :class="{
      'text-gray-300 cursor-not-allowed': disabled,
      'text-gray-300 hover:text-white hover:bg-white hover:bg-opacity-20': dark
    }"
    :disabled="disabled"
  >
    <icon :name="icon" :size="size"/>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '24'
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
}
</script>
