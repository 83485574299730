import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

const geocoder = new MapboxGeocoder({
  accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
  mapboxgl: mapboxgl,
  marker: false
})

export {
  mapboxgl,
  geocoder
}
