var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FeathersVuexPagination',{staticClass:"h-10",attrs:{"value":_vm.value,"latest-query":_vm.latestQuery},on:{"input":function (e) { return _vm.$emit('input', e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentPage = ref.currentPage;
var pageCount = ref.pageCount;
var toPage = ref.toPage;
var next = ref.next;
var prev = ref.prev;
var canNext = ref.canNext;
var canPrev = ref.canPrev;
return [_c('div',{staticClass:"flex flex-row flex-1 items-center mt-2 text-sm text-gray-700"},[_c('div',{staticClass:"hidden ml-2 w-24 md:block"},[_vm._v("Total: "+_vm._s(_vm.total))]),_c('div',{staticClass:"hidden mx-1 md:block"},[_c('select',{staticClass:"inline-block p-0 px-2 w-16 h-8 text-sm bg-white rounded border border-gray-200 shadow-sm focus:outline-none focus:border-gray-500 focus:ring-0",attrs:{"name":"perPage","title":"Items Per Page"},domProps:{"value":_vm.value.$limit},on:{"input":function (e) { return _vm.$emit('input', { $limit: e.target.value, $skip: _vm.value.$skip }); }}},[_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"20"}},[_vm._v("20")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")])])]),_c('div',{staticClass:"flex flex-1 items-center mx-1 lg:flex-initial"},[_c('input',{staticClass:"p-0 mr-2 w-12 h-8 text-sm text-center bg-white rounded border border-gray-200 shadow-sm focus:outline-none focus:border-gray-500",attrs:{"min":1,"max":pageCount,"title":"Current Page"},domProps:{"value":currentPage},on:{"input":function (event) { return toPage(event.target.value); }}}),_vm._v(" of "),_c('span',{staticClass:"mx-2 w-6",attrs:{"title":"Total Page"}},[_vm._v(_vm._s(pageCount))])]),_c('icon-button',{staticClass:"mx-px",attrs:{"icon":"chevron-left","title":"Previous Page","disabled":!canPrev},on:{"click":prev}}),_c('icon-button',{staticClass:"mx-px",attrs:{"icon":"chevron-right","title":"Next Page","disabled":!canNext},on:{"click":next}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }