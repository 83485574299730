<template>
  <div class="fixed right-0 bottom-0 z-50 p-4 w-80 transition-all pointer-events-none">
    <transition-group
      enter-active-class="transition ease-out transform"
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition ease-in transform"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        class="relative py-4 pr-10 pl-6 mb-2 text-gray-800 bg-white rounded-md shadow-lg pointer-events-auto select-none"
        v-for="n in notifications"
        :key="n.id"
        :class="{
          'bg-green-50 text-green-700': n.type === 'success',
          'bg-red-50 text-red-600': n.type === 'alert'
        }"
      >
        <icon-button icon="x" size="18" class="absolute right-2 top-3 w-8 h-8 text-current" @click="dismiss(n.id)"/>
        <p class="font-bold">{{ n.title }}</p>
        <p v-if="n.text">{{ n.text }}</p>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Notifications',
  data() {
    return {
      timer: {}
    }
  },
  computed: {
    notifications() {
      return this.$store.state.notifications
    }
  },
  watch: {
    notifications(val) {
      const { id, timeout } = { ...this.notifications[val.length - 1] }
      if (this.timer[id]) { clearTimeout(this.timer[id]) }
      if (timeout) {
        this.timer[id] = setTimeout(() => {
          this.$store.commit('removeNotification', { id })
        }, 3000)
      }
    }
  },
  methods: {
    dismiss(id) {
      if (this.timer[id]) { clearTimeout(this.timer[id]) }
      this.$store.commit('removeNotification', { id })
    }
  }
}
</script>
