<template>
  <slide-pop
    @close="$emit('toggle-form')"
    :title="productForm.id ? 'Edit Product' : 'Add Product'"
  >
    <base-form-input v-if="productForm.id" disabled label="SKU" :value="sku"/>
    <base-form-input label="Name" v-model="productForm.name"/>
    <brand-picker
      required
      v-model="productForm.brandId"
      :brand="productForm.brand"
      :suggestion="productForm.info ? productForm.info.pulledBrand : null"
    />
    <!-- value should use the string instead so it can determine which to select -->
    <base-form-select
      label="Category"
      v-model="productCategory"
      :error="!productCategory"
    >
      <option value="" disabled selected hidden>Pick Category</option>
      <option v-for="(c, i) in categories" :key="i" :value="c.title">{{ c.title }}</option>
    </base-form-select>
    <div class="flex gap-2 -my-6">
      <base-form-select class="w-1/3" label="Currency" v-model="productForm.info.currency">
        <option value="CAD">CAD</option>
        <option value="USD">USD</option>
      </base-form-select>
      <base-form-input class="ml-auto w-2/3" label="Estimated Value" v-model="productForm.value" icon="dollar-sign"/>
    </div>
    <label class="block my-6">
      <span class="text-gray-700">Approval Status</span>
      <div class="flex my-2">
        <input type="checkbox" class="mt-1 w-5 h-5 checkbox" v-model="productFormApproved"/>
        <span class="ml-4 text-xl select-none">{{ productFormApproved ? 'Approved' : 'Not Approved' }}</span>
      </div>
    </label>
    <div v-if="productForm.info && productForm.info.scraper" class="border-t">
      <h3 class="mt-3 mb-1 text-lg font-bold">Pulled Data</h3>
      <div class="flex">
        <label class="flex-shrink-0 mr-2 w-16 text-gray-700">Brand</label>
        <p>{{ productForm.info.pulledBrand }}</p>
      </div>
      <div class="flex">
        <label class="flex-shrink-0 mr-2 w-16 text-gray-700">From</label>
        <p><a :href="productForm.info.pulledFrom" target="_blank">{{ productForm.info.pulledFrom.split('/')[2] }}</a></p>
      </div>
      <div class="flex">
        <label class="flex-shrink-0 mr-2 w-16 text-gray-700">Date</label>
        <p>{{ formatDate(productForm.info.pulledOn) }}</p>
      </div>
      <label class="block mb-1 w-16 text-gray-700">Description</label>
      <textarea v-model="productForm.info.description" class="mb-8 w-full h-40 rounded-md border-gray-400"></textarea>
    </div>
    <template #controls="{ close }">
      <base-button class="block mb-2 font-medium bg-green-600" @click="saveProduct(close)">Save</base-button>
      <base-button v-if="productForm.id" class="block" red @click="deleteProduct(close)">Delete</base-button>
    </template>
  </slide-pop>
</template>

<script>
import { format, isValid, parseISO } from 'date-fns'
import { models } from 'feathers-vuex'
import { categories } from '../utils'
import BrandPicker from '@/components/BrandPicker'
import SlidePop from '@/components/SlidePop'

export default {
  props: ['data'],
  components: { BrandPicker, SlidePop },
  computed: {
    categories() {
      return categories
    },
    productForm() {
      return this.data
    },
    sku() {
      return 'RI-' + String(this.productForm.id).padStart(6, '0')
    },
    productFormApproved: {
      get() {
        return this.productForm.role === 'a'
      },
      set(val) {
        this.productForm.role = val ? 'a' : 'na'
      }
    },
    productCategory: {
      get() {
        return this.productForm.info?.categoryName ?? ''
      },
      set(val) {
        const item = this.categories.find(c => c.title === val)
        this.productForm.category = item.value
        this.productForm.info = {
          ...this.productForm.info,
          categoryName: item.title
        }
      }
    }
  },
  methods: {
    formatDate(d) {
      const formatting = 'eeee, MMMM d, yyyy h:mm a'
      return isValid(parseISO(d)) ? format(parseISO(d), formatting) : isValid(new Date(d)) ? format(new Date(d), formatting) : d
    },
    saveProduct(close) {
      const { Product } = models.api
      const product = new Product(this.productForm)
      product.save().then(() => {
        this.$store.commit('notify', { title: 'Success saving product', type: 'success' })
        close()
      })
    },
    deleteProduct(close) {
      if (confirm('Delete product?')) {
        const { Product } = models.api
        const product = new Product(this.productForm)
        product.role = 'del'
        product.patch().then(() => {
          this.$store.commit('notify', { title: 'Success deleting product', type: 'success' })
          close()
        })
      }
    }
  }
}
</script>
