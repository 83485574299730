<template>
  <div class="flex flex-wrap py-4 pr-2 pl-4 mb-3 bg-white rounded-md border border-gray-200 shadow cursor-pointer lg:mb-2 lg:py-2 lg:pr-4 hover:border-gray-400" @click="$emit('edit')">
    <template v-if="!loading">
      <div class="mb-3 w-5/6 lg:mb-0 lg:flex-1">
        <p class="text-lg leading-tight">{{ product.name }}</p>
        <p class="text-sm">
          <span v-if="product.brand" class="font-semibold text-r-green">{{ product.brand.name }}</span>
          <span v-else class="text-gray-400">No brand</span>
          <span v-if="filter === 'na' && !product.brand && product.info" class="text-gray-600">{{ ` / ${product.info.pulledBrand}` }}</span>
        </p>
      </div>
      <div class="flex justify-end items-start -mt-2 w-1/6 lg:hidden">
        <div class="flex justify-center items-center w-10 h-10" v-show="!isStaff">
          <icon :name="needAttention ? 'alert-triangle' : 'edit'" size="18" :class="[ needAttention ? 'text-red-600' : 'text-gray-400' ]"/>
        </div>
      </div>
      <div @click.stop class="flex-auto mb-2 w-full lg:mb-0 lg:flex-initial lg:w-32 lg:mx-4">
        <p class="select-all">{{ sku }}</p>
        <p class="text-sm text-gray-500">SKU</p>
      </div>
      <div class="flex-auto w-1/4 lg:flex-initial lg:w-28 lg:mx-4">
        <p><span v-if="product.info.currency">{{ product.info.currency }}</span> {{ product.value || '-' }}</p>
        <p class="text-sm text-gray-500">
          <span class="inline lg:hidden">Est. Value</span>
          <span class="hidden lg:inline">Estimated value</span>
        </p>
      </div>
      <div class="w-24 lg:w-28 lg:flex-initial lg:mx-4">
        <template v-if="product.role === 'na'">
          <icon name="slash" size="18" class="text-red-600"/>
          <p class="text-sm text-gray-500">Not Approved</p>
        </template>
        <template v-else-if="product.role === 'a'">
          <icon name="check-circle" size="18" class="text-green-500"/>
          <p class="text-sm text-gray-500">Approved</p>
        </template>
        <template v-else-if="product.role === 'del'">
          <icon name="minus" size="18" class="text-gray-500"/>
          <p class="text-sm text-gray-500">Deleted</p>
        </template>
      </div>
      <div class="hidden justify-center items-center w-10 lg:flex" v-show="!isStaff">
        <icon :name="needAttention ? 'alert-triangle' : 'edit'" size="18" :class="[ needAttention ? 'text-red-600' : 'text-gray-400' ]"/>
      </div>
    </template>
    <template v-else>
      <div class="w-full lg:w-1/2">
        <div class="mt-1 w-40 h-5 bg-gray-100"></div>
        <div class="my-0.5 w-20 h-4 bg-gray-100"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: null
    },
    filter: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isStaff() {
      return this.$store.getters.isStaff
    },
    needAttention() {
      return this.product.role === 'na' ||
        !(this.product.category > 0) ||
        !(this.product.brandId > 0)
    },
    sku() {
      return 'RI-' + String(this.product.id).padStart(6, '0')
    }
  }
}
</script>
