// src/feathers-client.js
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'

const host = process.env.NODE_ENV === 'production' ? 'https://production.rebelstork.k1.labs.avail.dev' : 'http://localhost:3030'
const socket = io(host, { transports: ['websocket'] })

const feathersClient = feathers()
  .configure(socketio(socket, {
    timeout: 25000
  }))
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    }
  })

export default feathersClient

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: 'id', // Must match the id field in your database table/collection
    whitelist: ['$regex', '$options'],
    paramsForServer: ['$like', '$iLike']
  }
)

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex, host, socket }
