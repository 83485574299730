<template>
  <div class="block relative" :class="[ tight ? 'mb-2' : 'my-6' ]">
    <label class="text-gray-700">{{ label }}</label>
    <select
      class="block mt-1 w-full text-xl rounded-md border shadow-sm focus:border-gray-700 focus:ring focus:ring-gray-100"
      :class="[ error ? 'border-red-600 ring ring-red-50' : disabled ? 'border-gray-300' : 'border-gray-400' ]"
      :type="type"
      :value="value"
      :required="required"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
    >
      <slot></slot>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    tight: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    label: String,
    value: [String, Number]
  }
}
</script>
