<template>
  <router-link :to="`/sell/${item.id}`" class="flex flex-wrap py-4 pr-2 pl-4 mb-3 bg-white rounded-md border border-gray-200 shadow cursor-pointer lg:mb-2 lg:py-2 lg:pr-4 hover:border-gray-400" :class="{ 'bg-blue-50 border-blue-300': selfScheduled }">
    <div class="mb-3 w-full lg:mb-0 lg:flex-1">
      <p class="text-lg font-bold leading-tight">
        <span v-if="needInfo" title="Need Info">
          <icon name="flag" class="mr-1 text-red-600" size="16"/>
        </span>
        <span v-if="selfScheduled" title="Self Scheduled">
          <icon name="flag" class="mr-1 text-blue-600" size="16"/>
        </span>
        {{ `RS-${item.id}` }} {{ data.name }}
      </p>
      <p class="leading-tight">{{ logistic }}</p>
    </div>
    <div class="mb-3 w-full lg:mb-0 lg:w-40">
      <p>{{ statusToText }}</p>
      <p class="text-sm text-gray-500">Status</p>
    </div>
    <div class="flex-auto my-1 w-1/2 lg:my-0 lg:flex-initial lg:w-28 lg:mx-4">
      <p>${{ estimates }}</p>
      <p class="text-sm text-gray-500">
        <span class="inline lg:hidden">Est. Value</span>
        <span class="hidden lg:inline">Estimated value</span>
      </p>
    </div>
    <div class="flex-auto my-1 w-1/2 lg:my-0 lg:flex-initial lg:w-28 lg:mx-4">
      <p>
        {{ data.products.length + data.customProducts.length }}
        <span v-if="data.customProducts.length" title="Custom Item(s)">({{ data.customProducts.length }})</span>
      </p>
      <p class="text-sm text-gray-500">Items <span v-if="data.customProducts.length">(custom)</span></p>
    </div>
    <div class="flex-auto my-1 w-1/2 lg:my-0 lg:flex-initial lg:w-28 lg:mx-4">
      <p>{{ date }}</p>
      <p class="text-sm text-gray-500">Pickup Date</p>
    </div>
    <div class="flex-auto my-1 w-1/2 lg:my-0 lg:flex-initial lg:w-28 lg:mx-4">
      <p>{{ submittedDate }}</p>
      <p class="text-sm text-gray-500">Submitted Date</p>
    </div>
  </router-link>
</template>

<script>
import { format, parseISO, isSameMinute } from 'date-fns'
import { statusToText } from '../utils'

export default {
  name: 'SellItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    data() {
      return this.item.data
    },
    statusToText() {
      return statusToText[this.item.status]
    },
    logistic() {
      return this.data.logistics.type === 'Unset'
        ? 'Pickup / Drop-off'
        : this.data.logistics.type === 'Pickup'
          ? `Pickup: ${this.data.address.replace(/,[^,]+$/, '')}`
          : `Drop-off: ${this.data.logistics.dropoffLocationId}`
    },
    estimates() {
      return this.data.products.reduce((sum, p) => parseFloat(parseFloat(sum) + parseFloat(p.estimate)).toFixed(2), '0.00')
    },
    date() {
      return this.data.logistics.date ? format(parseISO(this.data.logistics.date), 'd MMM yyyy') : 'No Date'
    },
    submittedDate() {
      return format(parseISO(this.item.createdAt), 'd MMM yyyy')
    },
    needInfo() {
      return this.data.needInfo ?? false
    },
    selfScheduled() {
      return this.item.status === 'nc' && (this.data.selfScheduled ?? false)
    },
    isNew() {
      return this.item.updater === 0 && this.item.status === 'nc' && isSameMinute(parseISO(this.item.createdAt), parseISO(this.item.updatedAt))
    }
  }
}
</script>
