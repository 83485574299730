import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import Home from './views/Home'
import Login from './views/Login'

import Dashboard from './views/Dashboard'
import Sell from './views/Sell'
import Brands from './views/Brands'
import Products from './views/Products'
import Postal from './views/Postal'
import PostalSchedule from './views/PostalSchedule'
import PickupLocations from './views/PickupLocations'
import Users from './views/Users'
import Account from './views/Account'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', name: 'Dashboard', component: Dashboard },
      { path: '/sell', name: 'Sell', component: Sell },
      { path: '/sell/:id', name: 'SellDetail', component: () => import('./views/SellDetail') },
      { path: '/brands', name: 'Brands', component: Brands },
      { path: '/products', name: 'Products', component: Products },
      { path: '/postal', name: 'Postal', component: Postal },
      { path: '/postal/schedule', name: 'PostalSchedule', component: PostalSchedule },
      { path: '/pickup', name: 'PickupLocations', component: PickupLocations },
      { path: '/users', name: 'Users', component: Users },
      { path: '/account', name: 'Account', component: Account }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch('auth/authenticate')
      .then(() => {
        next()
      })
      .catch(() => {
        next('/login')
      })
  } else {
    next()
  }
})

export default router
