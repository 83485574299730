<template>
  <div class="container flex flex-col px-4 pt-10 mx-auto h-screen md:px-8">
    <div class="flex items-center">
      <icon-button v-if="loading" icon="loader" class="animate-spin"/>
      <icon-button v-else icon="arrow-left" @click="$router.push('/postal')" />
      <h1 class="flex-1 pb-1 ml-2">Pickup Scheduling</h1>
      <h2 class="mr-4 text-xl font-bold text-r-dark xl:text-2xl">
        {{ pageTitle }}
      </h2>
      <icon-button icon="chevron-left" @click="prev" class="mr-1" />
      <icon-button icon="chevron-right" @click="next" class="mr-1" />
      <v-date-picker v-model="currentDate" color="green">
        <template #default="{ togglePopover }">
          <icon-button
            icon="calendar"
            ref="button"
            @click.stop="openCalendar($event, togglePopover)"
          />
        </template>
      </v-date-picker>
    </div>
    <div class="grid gap-4 pb-12 mt-4 lg:grid-cols-2">
      <div
        v-for="(items, key) in postalGroups"
        :key="key"
        class="px-3 pt-2 pb-1 bg-white rounded-md shadow"
      >
        <base-toggle after :label="key" :value="selectedGroup(key)" @input="toggleGroup($event, key)" class="font-bold"/>
        <div class="flex flex-wrap mt-1 ml-12">
          <span
            title="Toggle"
            v-for="item in items"
            :key="item.id"
            class="py-px px-2 mr-1 mb-1 text-sm rounded cursor-pointer select-none"
            :class="[ selected(item) ? 'text-green-700 bg-green-100' : 'text-gray-700 bg-gray-100' ]"
            @click="toggleItem(item)"
          >{{ item.id }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeFindMixin, models } from 'feathers-vuex'
import { addDays, format, subDays } from 'date-fns'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'PostalSchedule',
  components: {
    'v-date-picker': DatePicker
  },
  mixins: [
    makeFindMixin({ service: 'postal', local: true })
  ],
  data() {
    return {
      loading: true,
      pagination: {
        $limit: 50,
        $skip: 0
      },
      currentDate: new Date()
    }
  },
  computed: {
    pageTitle() {
      return format(this.currentDate, 'eee, dd MMM yyyy')
    },
    dateStr() {
      return format(this.currentDate, 'yyyy-MM-dd')
    },
    postalParams() {
      return {
        query: { $sort: { id: 1 } },
        paginate: false
      }
    },
    postalGroups() {
      return this.postal.reduce((storage, item) => {
        const group = item.city
        storage[group] = storage[group] || []
        storage[group].push(item)
        return storage
      }, {})
    }
  },
  methods: {
    next() {
      this.currentDate = addDays(this.currentDate, 1)
    },
    prev() {
      this.currentDate = subDays(this.currentDate, 1)
    },
    openCalendar(e, toggle) {
      toggle({ ref: e.target })
    },
    async timeout(t) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(`Completed in ${t}`)
        }, t)
      })
    },
    async toggleItem(item) {
      const { Postal } = models.api
      let schedule = item.ancillary.schedule ?? []
      if (!this.selected(item)) {
        schedule.push(this.dateStr)
      } else {
        schedule = schedule.filter(s => s !== this.dateStr)
      }
      const postal = new Postal({ ...item, ancillary: { ...item.ancillary, schedule } })
      postal.patch().catch(error => {
        console.log(`Error saving ${postal.id}`, error.message)
      })
    },
    async toggleGroup(bool, key) {
      const postalGroup = this.postalGroups[key]
      this.$store.commit('notify', { title: `Processing postal ${key}` })
      const { Postal } = models.api
      const batch = []
      postalGroup.forEach((item) => {
        let push = true
        let schedule = item.ancillary.schedule ?? []
        if (bool) {
          if (!schedule.includes(this.dateStr)) {
            schedule.push(this.dateStr)
          } else {
            push = false
          }
        } else {
          schedule = schedule.filter(s => s !== this.dateStr)
        }
        if (push) {
          const postal = new Postal({ id: item.id })
          const promise = postal.patch({ data: { ancillary: { schedule } } })
            .catch(error => {
              console.log(`Error saving ${postal.id}`, error.message)
            })
          batch.push(promise)
        }
      })

      Promise.all(batch)
        .then(() => {
          this.$store.commit('notify', { title: 'Success setting postal schedule', type: 'success' })
        })
        .catch((e) => {
          this.$store.commit('notify', { title: 'Error in setting postal schedule', text: e.message, type: 'alert' })
        })
    },
    selectedGroup(key) {
      return this.postalGroups[key]
        .map(item => this.selected(item))
        .reduce((a, b) => a && b)
    },
    selected(item) {
      return !!item.ancillary.schedule && item.ancillary.schedule.includes(this.dateStr)
    }
  },
  async mounted() {
    const { $limit, $skip } = this.pagination
    await this.findPostal({ query: { $limit, $skip } })
    while (this.postalLatestQuery.response.total > ($limit + this.pagination.$skip)) {
      this.pagination.$skip = $limit + this.pagination.$skip
      await this.findPostal({
        query: { $limit, $skip: this.pagination.$skip }
      })
    }
    this.loading = false
  }
}
</script>
