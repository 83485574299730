<template>
  <transition
    appear
    appear-class="opacity-0"
    appear-active-class="transition-all duration-200 ease-in"
    leave-to-class="opacity-0"
    leave-active-class="transition-all duration-200 ease-in"
    @appear="visible = true"
    @after-appear="$emit('shown')"
  >
    <div class="fixed inset-0 z-50 bg-black bg-opacity-60" @click="close">
      <transition
        enter-active-class="transition duration-200 ease-out transform"
        enter-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition duration-200 ease-in transform"
        leave-class="translate-x-0"
        leave-to-class="translate-x-full"
        @leave="$emit('close')"
      >
        <div
          v-if="visible"
          class="flex overflow-y-auto fixed top-0 right-0 bottom-0 flex-col p-4 bg-white shadow-lg"
          :class="[ wide ? 'w-full md:w-1/2' : 'w-80 md:w-96' ]"
          @click.stop
        >
          <small v-if="code">{{ code }}</small>
          <div class="flex items-center">
            <h1 class="flex-1">{{ title }}</h1>
            <icon-button class="flex-shrink-0" icon="x" @click="close" title="Close"/>
          </div>
          <p v-if="description">{{ description }}</p>
          <div class="flex-1">
            <slot/>
          </div>
          <slot name="controls" :close="close"></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SlidePop',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    close() {
      this.visible = false
    }
  }
}
</script>
