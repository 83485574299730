<template>
  <div class="container px-4 pt-10 mx-auto md:px-8">
    <div class="flex items-start">
      <h1 class="flex-1">Service Area + Schedules</h1>
      <postal-local-modal/>
      <base-button
        icon-right="calendar"
        outline
        class="hidden flex-grow-0 mr-1 lg:flex"
        @click="$router.push('/postal/schedule')"
      >
        Schedule
      </base-button>
      <base-button
        icon-right="plus"
        class="flex-grow-0 bg-green-600"
        @click="toggleForm"
      >
        Create
      </base-button>
    </div>
    <div class="flex flex-wrap items-center py-2">
      <search v-model="search" label="Search" class="w-full lg:w-5/12 lg:mr-auto"/>
      <div class="flex items-center w-full lg:w-auto lg:justify-end">
        <pagination v-model="pagination" :latest-query="latestQuery"/>
      </div>
    </div>
    <div class="mb-12">
      <div
        v-for="postal in items"
        :key="postal.id"
        class="flex flex-wrap px-3 pt-1 pb-3 mb-2 bg-white rounded-md border border-gray-200 shadow md:p-2 hover:border-gray-400"
      >
        <div class="flex flex-1 items-center w-full text-lg md:w-1/2">
          <span class="mr-4 font-mono font-bold text-gray-500 md:ml-3">{{ postal.id }}</span>
          <span class="font-bold">{{ postal.city }}</span>
          <icon-button class="flex-shrink-0 mx-px ml-auto md:hidden" icon="edit" size="18" @click="selectItem(postal)"/>
        </div>
        <div class="flex justify-between items-center w-full md:w-1/2 md:justify-end">
          <span class="flex mr-2">
            <span v-for="item in postal.service" :key="item" class="px-3 mr-2 leading-7 bg-gray-100 rounded">{{ item }}</span>
            <span class="px-2 ml-2 font-mono text-sm font-bold leading-7 text-blue-600 uppercase bg-blue-50 rounded md:ml-3">{{ postal.tag }}</span>
          </span>
          <icon-button class="hidden flex-shrink-0 mx-px md:flex" icon="edit" size="18" @click="selectItem(postal)"/>
        </div>
      </div>
    </div>
    <postal-form
      v-if="isFormVisible"
      :data="formData"
      :is-editing="isEditing"
      @toggle-form="toggleForm"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'

import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import PostalForm from '@/components/PostalForm'
import PostalLocalModal from '@/components/PostalLocalModal'

const initialState = () => ({
  isFormVisible: false,
  isEditing: false,
  formData: {
    id: '',
    city: '',
    suburb: '',
    tag: '',
    service: [],
    ancillary: {
      schedule: []
    }
  }
})

export default {
  name: 'Postal',
  components: {
    Pagination,
    Search,
    PostalForm,
    PostalLocalModal
  },
  setup(_, { root }) {
    const { Postal } = models.api

    const search = ref('')
    const pagination = ref({
      $limit: 10,
      $skip: 0
    })
    const region = computed(() => root.$store.getters.region)

    const fetchParams = computed(() => {
      const query = {
        $or: [
          { id: { $like: '%' + search.value.toUpperCase().trim() + '%' } },
          { city: { $iLike: '%' + search.value.trim() + '%' } }
        ],
        $sort: {
          id: 1
        }
      }
      if (region.value !== '') {
        Object.assign(query, { tag: region.value })
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'postalFetch' }
    })

    const params = computed(() => {
      const query = {
        $or: [
          { id: { $regex: search.value.toUpperCase().trim() } },
          { city: { $regex: search.value.trim(), $options: 'i' } }
        ],
        $sort: {
          id: 1
        }
      }
      if (region.value !== '') {
        Object.assign(query, { tag: region.value })
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'postalParams' }
    })

    const { items, latestQuery, isPending } = useFind({ model: Postal, params, fetchParams })

    return {
      search,
      pagination,
      items,
      latestQuery,
      isPending
    }
  },
  data() {
    return {
      ...initialState()
    }
  },
  methods: {
    toggleForm() {
      this.isFormVisible = !this.isFormVisible
      if (!this.isFormVisible) {
        Object.assign(this.$data, initialState())
      }
    },
    selectItem(item) {
      this.isEditing = true
      this.formData = item.clone()
      this.toggleForm()
    }
  }
}
</script>
